.home-page-container {
  padding-top: 10rem;
}

.go-last-card {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  background: #555;
  margin: 0.75rem;
  padding: 1rem;
  border-radius: 50%;
}
.copyright-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  .txt{
    font-size: 10px;
  }
  :first-child{
    margin:0px;
  }
  :nth-of-type(2){
    margin:0px 0px 0.5em 0px;
  }
}
.modal-dialog{
  margin: 0px!important;
}
.modal-content{
  height: 100vh!important;
  max-height: 100vh;
  display: flex!important;
  flex-direction: column!important;
}
.modal-header{
  display: flex;
  flex: 1;
}
.modal-body{
  display: flex;
  flex: 3;
}
.modal-footer{
  display: flex;
  flex: 1;
  justify-content: center!important;
}
