.container.app-header {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 100;
  background-color: #000;
  padding: 0 30px 0.5rem 30px;
  max-width: initial;

  .header {
    height: 70px;

    .app-name {
      position: absolute;
      top: 1.5rem;
      font-size: 1.2rem;
      color: #fff;
      font-weight: bold;

      .highlighted {
        color: #0099cc;
      }
    }

    .logo {
      right: 15px;
      position: absolute;
      max-width: 45%;
      height: auto;

    }
  }

  .menu-search {
    display: flex;

    .search {
      display: flex;
      width: 100%;
      background-color: #454545;
      margin-left: 0.5rem;

      &.disabled {
        background-color: #0a0a0a;
      }

      input {
        background-color: transparent;
        color: #fff;
      }

      button {
        padding: 0 15px;
      }
    }

    button {
      padding: 0;
    }
  }

  .tab-container {
    margin: 1rem;
    overflow-y: hidden;
    overflow-x: scroll;
    white-space: nowrap;

    .tab-header-title {
      margin: 0 0.5rem;
      font-weight: bold;
      color: #0099cc;
      text-transform: uppercase;

      &:hover {
        cursor: pointer;
      }

      &.active {
        border-bottom: 1px solid #cc0000;
      }

      .has-unread {
        background-color: #cc0000;
        border-radius: 50%;
        width: 6px;
        height: 6px;
      }
    }
  }
}


@media (min-width: 768px) {
  .container.app-header {
    .tab-container {
        display: flex;
        justify-content: center;
        overflow-x: hidden;
    }
  }
}