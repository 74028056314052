.legal {
    margin: 2rem;
    text-align: justify;
  
    h2,
    h3,
    h4 {
      margin: 1.5rem 0 0.5rem 0;
      color: #6495ed;
    }
    ul {
      padding-inline-start: 1rem;
    }

    h2 { 
      font-size: 22px ;
    }
    h3 { 
      font-size: 16px ;
    }
    span{
      font-size: 13px ;
    }
  }
  