.landing-container{
    flex-direction: column;
    border-color: red 1px solid;
    width: 100%;
    height: 100vh;
    .first-page{
        display: flex;
        flex: 3;
        flex-direction: column;
        height: 100vh;
        width: 100%;
        overflow: hidden;
        .header{
            display: flex;
            flex:1;
            .logo-container{
                flex:1;
                padding-left: 2em;
                .logo{
                    width: 209px;
                    padding: 0.5rem;
                }
            }
            .btn{
                padding: 0px;
                height: 50px;
                .login-container{
                    flex:1;
                    padding-right: 2em;
                    .go-login{
                        float: right;
                        font-size: 12px;
                        .link{
                            color: #2196f3;
                        }
                    }
                }
            }
        }
        .section{
            flex:8;
            background-image: url('/assets/img/ImagenPrincipal.png');
            background-repeat: no-repeat;
            background-size: cover;
            display: flex;
            flex-direction: column;
            div{
                display: flex;
                flex: 1;
                align-items: center;
                justify-content: center;
                .center{
                    color: white;
                    font-size: 2.5em ;
                    text-align: center;
                    font-weight: bold;
                    margin: 0 20% 0 20%;
                }
                .top{
                    margin-top: 5em;
                    width: calc(26% + 0.1vw) ;
                }
                .bottom{
                    margin-bottom: 5em;
                }
                .btn-img{
                    background: none;
                    border: 0px solid;
                    width: calc(16% + 1vw) ;
                    img{
                        width: calc(90% + 1vw) ;
                    }
                    img:hover{
                        background: #bf896c78;
                    }
                }
            }
        }
    }

    .last-page{
        display: flex;
        flex: 3;
        flex-direction: column;
        height: auto;
        background: #616161;
        .section{
            flex:9;
            .download-container{
                display: flex;
                flex: 1;
                margin: 0 auto;
                padding-bottom: 70px;
                .android{
                    justify-content: right;
                    text-align: right;
                    margin-right: 30px;
                    height: 90px;
                    width: 324px;
                }
                .ios{
                    justify-content: left;
                    text-align: left;
                    height: 90px;
                    width: 324px;
                }
                .link-container{
                    flex:1;
                    display: flex;
                }
            }
            .ios-text-container{
                display: flex;
                justify-content: center;
                justify-items: center;
                align-items: center;
                align-content: center;
                padding-bottom: 3em;

                .text{
                    border: 1px solid #A4A4A485;
                    display: flex;
                    .sc-bdVaJa{
                        font-size: 5em;
                        border-right: 1px solid #A4A4A485;
                    }
                    p{  
                        margin:10px;
                        padding: 10px;
                        color: #FFF;
                    }
                }
            }
        }
        .footer{
            flex:2;
            display: flex;
            background: #37bbed;
            flex-direction: row;
            .left{
                flex: 1;
                float:left;
                padding-top: 2.5em;
                padding-left: 6em;
            }
            .right{
                flex: 1;
                img{
                    float:right
                }
                padding-top: 6em;
                padding-right: 2.5em;
                padding-bottom: 1em;

            }
        }
    }

    .section{
        display: flex;
        flex:1;
        height: auto;
        flex-direction: column;
        .title-container{
            flex:1;
            padding: 2em;
        }
        .title{
            text-align: center;
            flex: 1;
        }
        .spacing{
            flex:1;
        }
        .list-card{
            display: grid;
            flex:2;
            grid-template-columns: repeat(4, 4fr);
            padding: 0;
            width: 100%;
            .card{
                border-radius: 0px;
                width: 100%;
                align-items: center;
                background: #757575;
                border: 2px solid #FFF;
                padding-top: 40px;
                padding-bottom: 40px;
                .img{
                    width:calc(15% + 1vw) ;
                }
                .title{
                    font-weight: bold;
                    padding-top: 1.5em;
                    color:#FFF;
                }
            }
        }

        .install-container{
            display: flex;
            flex: 1;
            .img-container{
                flex:1;
                display: flex;
                justify-content: center;
                padding-bottom: 3em;
                .img{
                    width: 60%;
                    height: auto;
                    display: block;   
                }
                
            }
            .text{
                flex:3;
                padding:5% 0 2em 5%;
                p{
                    font-size: 1.2rem ;
                    font-weight: 0px;
                }
            }
        }
    }

    .button-image{
        background: transparent;
        border: 1px solid #838383;
        height: 85px;
        width: 324px;
        display: flex;
        img{
            width: 100%;
            height: 100%;
        }
    }
    .button-image:hover{
        background: #91919157;
    }
    .button-image:active{
        border: 1px solid #a8a4a4;
        background: #91919157;
    }
    
}


@media only screen and (max-width: 710px){ 
    /* smartphones, iPhone, portrait 480x320 phones */ 
    .landing-container {
        .section{
            .list-card{
                flex:5;
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                .card {
                    border-radius: 0px;
                    align-items: center;
                    justify-content: center;
                    background: #757575;
                    border: 2px solid #FFF;
                    margin: 0px;
                    .img{
                        width:calc(20% + 1vw) ;
                    }
                    .text{
                        p{
                            font-size: calc(20% + 1vw) ;
                            font-weight: 0px;
                        }
                    }
                }
            }
        }
    }
    .landing-container .first-page .section div .center {
        color: white;
        font-size:calc(140% + 1vw)!important;
        text-align: center;
        font-weight: bold;
        margin: 0 30px
    }
    .landing-container .first-page .section div .top {
        margin-top: 5em;
        width: calc(60% + 0.1vw);
    }
    .landing-container .first-page .section div .btn-img {
        background: none;
        border: 0px solid;
        width: calc(60% + 1vw);
    }
    .logo-container{
        flex:1;
        padding-left: 0;
        .logo{
            width: 95%;
            padding: 0;
        }
    }
    .landing-container .first-page .header .login-container {
        flex: 1 1;
        padding-right: 0px;
    }
    .section{
        .install-container{
            flex-direction: column-reverse;
        }
    }
    .landing-container .section {
        height: auto;
    }
    .go-login{
        font-size: 10px;
    }
    .landing-container .first-page .header .logo-container .logo {
        width: 150px;
    }

    .landing-container .last-page .footer .left {
        padding-top: 1em;
        padding-left: 10px;
        img{
            width: 150px;
        }
    }
    .landing-container .last-page .footer .right {
        padding-top: 1.5em;
        padding-right: 0.5em;
        img{
            width: 40px;
            padding-bottom: 5px;
            padding-right: 10px;
        }
    }
    .landing-container .last-page .section .download-container .android {
        margin-right: 0px;
    }
    .landing-container .last-page .section .download-container {
        margin: 0;
    }
    .landing-container .button-image {
        width: 150px;
        height: 70px;
        margin: 0 auto;
    }
    .landing-container .section .install-container .img-container {
        padding-bottom: 0em; 
    }

    .container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
        padding-right: 0px!important; 
        padding-left: 0px!important; 
    }
    .landing-container .section .install-container .text {
        padding: 5% 11% 2em 11%;
    }
    .landing-container .first-page .header .logo-container {
        padding-left: 1em;
    }
    .landing-container .first-page .header {
        display: flex;
        flex: 0;
        height: 60px;
    }
    .landing-container .last-page .section .download-container {
        padding-bottom: 0px;
    }
    .last-page .section .title-container {
        padding: 1em;
    }
    .landing-container .last-page .section {
        .ios-text-container{
            .text{
                width: 320px;
                flex-direction: column;
                .sc-bdVaJa{
                    border: 0px solid #FFF;
                    margin:0 auto;
                    width: 100%;
                    border-bottom: 1px solid #A4A4A485;
                    padding: 15px;
                }
                p{  
                    padding:0px;
                    font-size: 13px;
                }
            }
        }
    }
    
}

@media (min-width:711px) and (max-width:961px)  { 
    /* tablet, landscape iPad, lo-res laptops ands desktops */ 
    .landing-container .first-page .section div .btn-img {
        width: calc(36% + 1vw);
    }
    .landing-container .first-page .section div .top {
        width: calc(51% + 0.1vw);
    }
    .landing-container .last-page .section .download-container .android {
        padding-right: 30px;
        // margin-right: 0px;
    }
    .landing-container .last-page .footer .left {
        padding-left: 2em;
    }
    .landing-container .last-page .footer .right {
        padding-right: 1.5em;
    }
    .landing-container .last-page .section .download-container {
        margin: 0 auto;
    }
    .landing-container .last-page .section {
        .ios-text-container{
            .text{
                margin:auto 10px;
            }
            p{  
                    padding:0px;
            }
        }
    }

}
@media (min-width:500px) and (max-width:961px) and (orientation: landscape) { 
    /* tablet, landscape iPad, lo-res laptops ands desktops */ 
    .landing-container .first-page .section div .btn-img {
        width: calc(25% + 1vw);
    }
    .landing-container .first-page .section div .top {
        width: calc(25% + 0.1vw);
        margin-top: 0em;
    }
    .landing-container .last-page .section .download-container .android {
        padding-right: 30px;
    }
    .landing-container .first-page .section div .center {
        font-size: 1.7em;
    }
    .landing-container .first-page .section div .bottom {
        margin-bottom: 0em;
    }
    .landing-container .last-page .footer .left {
        padding-left: 2em;
    }
    .landing-container .last-page .footer .right {
        padding-right: 1.5em;
    }
    .landing-container .first-page .header .logo-container .logo {
        height: 70px;
    }
    .landing-container .button-image {
        width: 245px;
        margin: 0 auto;
    }
    .landing-container .last-page .section .download-container {
        margin: 0 auto;
    }
    .landing-container .last-page .section {
        .ios-text-container{
            .text{
                margin:auto 10px;
            }
            p{  
                    padding:0px;
            }
        }
    }
}
